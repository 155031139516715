<template>
  <div class="container">
    <img
      src="/img/femak-logo.svg"
      alt="Femak Logo"
      class="logo animate__animated animate__zoomIn animate__delay-2ms"
    />
    <div class="content">
      <div class="femak">
        <img
          src="/img/femak-logo.svg"
          alt="Femak Logo"
          class="logo-mobile animate__animated animate__zoomIn"
        />
        <a
          class="card animate__animated animate__backInLeft animate__delay-7ms"
          href="http://www.pressori.com/"
        >
          <h2>
            Accessori per industria ceramica<br />
            <span>Accessories for ceramic machineries</span>
          </h2>

          <button type="button" class="btn btn--yellow">
            <span>Visita</span>
          </button>
        </a>
      </div>
      <div class="golia">
        <a
          class="card animate__animated animate__backInRight animate__delay-7ms"
          href="http://www.golia.net/"
        >
          <div class="golia-container">
            <h2>
              Carrelli sollevatori <br />
              <span>Lifting trolleys</span>
            </h2>
            <img
              src="/img/golia-logo.svg"
              alt="Golia Logo"
              class="logo-golia"
            />
          </div>
          <button type="button" class="btn btn--red">
            <span>Visita</span>
          </button>
        </a>
      </div>
    </div>

    <div class="footer">
      <div class="footer__container">
        <div>
          <p class="oswald">Femak srl</p>
          <p>
            Via Toscana 3/B - 41051 Montale Rangone (MO) Italia <br />
            Tel +39-059-530950 - Fax +39-059-530951 - femak@femak.it
          </p>
        </div>
        <div class="footer__column footer__column--last">
          <p>P.IVA/C.F./R.I. Modena: 02540360365 - C.S.: €200.000,00 i.v.</p>

          <p>© Tutti i diritti riservati</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";

export default {
  name: "App",
};
</script>

<style>
* {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

p {
  margin: 0;
  line-height: 25px;
}

h1,
h2,
h3,
h4,
h2 span,
.oswald {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  margin: 0;
}

h2 {
  font-size: 22px;
  line-height: 25px;
}

h2 span {
  font-size: 18px;
}

body {
  margin: 0;
}

button {
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0 none;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.content > div {
  position: relative;
  width: calc(50% - 3px);
  margin-bottom: 6px;
  padding-top: 80px;
  background-size: cover;
  background-position: center;
}

.golia {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/img/golia-bg.jpg");
}

.femak {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/img/femak-bg.jpg");
}

.card {
  display: block;
  width: 70%;
  padding: 30px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  color: #000;
  text-decoration: none;
}

.femak .card h2 {
  margin-bottom: 30px;
}

.card p {
  margin-bottom: 35px;
}

.card button span {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}

.card:hover button span {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.btn {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 50px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
}

.btn--yellow {
  color: #005ba0;
  background: #ffdd00;
}

.btn--red {
  color: #fff;
  background: #e13022;
}

.logo {
  display: block;
  position: absolute;
  width: 200px;
  top: 50px;
  left: 50%;
  margin-left: -100px;
  z-index: 10;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.logo-golia {
  display: block;
  width: 100px;
  height: auto;
}

.golia-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.footer {
  color: #fff;
  background: #000;
  padding: 20px 80px;
  margin-top: auto;
}

.footer__container {
  display: flex;
  justify-content: space-between;
}

.footer p {
  font-size: 12px;
}

.footer__column--last {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.footer__column--last p:last-child {
  margin-top: auto;
}

.footer .oswald {
  font-size: 15px;
  margin-bottom: 10px;
}

.animate__animated.animate__delay-7ms {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.animate__animated.animate__delay-2ms {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.logo-mobile {
  display: none;
}

@media only screen and (max-width: 1280px) {
  .card {
    width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .card {
    padding: 30px;
  }

  .logo--golia {
    height: 65px;
  }

  h2 {
    font-size: 17px;
    line-height: 20px;
  }

  h2 span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 768px) {
  .content > div {
    width: 100%;
    padding-top: 0;
  }

  .card {
    position: static;
    -webkit-transform: none;
    transform: none;
    margin: 30px auto;
  }

  .card h2 {
    margin-bottom: 10px;
  }

  .card p {
    margin-bottom: 15px;
  }

  .logo {
    display: none;
  }

  .logo-mobile {
    display: block;
    width: 150px;
    margin: 50px auto 20px auto;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

  .femak {
    flex-direction: column;
  }

  .footer__container {
    display: block;
  }

  .footer__column--last {
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;
    margin-top: 20px;
  }

  .footer__column--last p:last-child {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .card {
    width: 90%;
  }

  .footer__column--last p:last-child {
    margin-left: 0;
  }

  .footer {
    padding: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .logo-golia {
    width: 80px;
  }
}
</style>
